import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Progress from "../components/progress"
import TokenExist from "../components/tokenExists"
import ClientOnly from "../components/clientOnly"
import RedirectTimer from "../components/redirectTimer"
import {
  ajax,
  API_CALL_STATUSES,
  getCookie,
  getQueryParam,
  getRedirectPathFromUrl,
} from "../utils"
import CenteredContainer from "../components/CenteredContainer"

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: getQueryParam("t") || "",
      verifyStatus: API_CALL_STATUSES.IDLE,
      timer: 5,
    }
  }

  componentDidMount() {
    if (getCookie("token")) {
      return
    }
    const { token } = this.state
    if (!token) {
      return
    }
    this.setState({
      verifyStatus: API_CALL_STATUSES.PROGRESS,
    })
    ajax({
      path: `user/verify?token=${token}`,
      type: "PUT",
      success: () => {
        this.setState({
          verifyStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: () => {
        this.setState({
          verifyStatus: API_CALL_STATUSES.ERROR,
        })
      },
    })
  }

  render() {
    const { verifyStatus, token, timer } = this.state
    if (getCookie("token")) return <TokenExist title="Verify Email" />
    return (
      <Layout bgimage stickyFooter dark>
        {verifyStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <CenteredContainer>
          <ClientOnly>
            {!token && (
              <div id="noToken">
                <h3>UNAUTHORIZED</h3>
                <p className="subText">
                  You are not allowed to view this page.
                  <br />
                  Go to{" "}
                  <Link to={`/login/${getRedirectPathFromUrl()}`}>
                    Login page
                  </Link>
                </p>
              </div>
            )}
            {verifyStatus === API_CALL_STATUSES.PROGRESS && (
              <div id="pending">
                <h3>VERIFYING EMAIL ADDRESS</h3>
                <p className="subText">Please wait...</p>
              </div>
            )}
            {verifyStatus === API_CALL_STATUSES.SUCCESS && (
              <div id="success">
                <h3>VERIFICATION SUCCESSFUL</h3>
                <p className="subText">
                  <RedirectTimer
                    initTime={timer}
                    redirectUrl={`/login/${getRedirectPathFromUrl()}`}
                    redirectText="login page"
                  />
                </p>
              </div>
            )}
            {verifyStatus === API_CALL_STATUSES.ERROR && (
              <div id="errorWrapper">
                <h3>VERIFICATION FAILED</h3>
                <div id="error">
                  <p>
                    <Link to={`/resendVerify/${getRedirectPathFromUrl()}`}>
                      Click here
                    </Link>{" "}
                    to resend the verification email
                  </p>
                </div>
              </div>
            )}
          </ClientOnly>
        </CenteredContainer>
      </Layout>
    )
  }
}

export default VerifyEmail
