import React from "react"

import { window } from "browser-monads"
import { Link } from "gatsby"

import { API_CALL_STATUSES, authAjax, getRedirectPathFromUrl } from "../utils"
import Layout from "./layout"
import Progress from "./progress"
import ClientOnly from "./clientOnly"
import RedirectTimer from "./redirectTimer"

class TokenExist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timer: props.timer || 5,
      userData: {},
      userStatus: API_CALL_STATUSES.IDLE,
    }
    this.getUserDetails = this.getUserDetails.bind(this)
  }

  componentDidMount() {
    this.getUserDetails()
  }

  getUserDetails = () => {
    this.setState({
      userStatus: API_CALL_STATUSES.PROGRESS,
    })
    authAjax({
      path: "user/authenticate",
      type: "GET",
      success: () => {
        authAjax({
          path: "user/info",
          type: "GET",
          success: res => {
            this.setState({
              userData: res.data,
              userStatus: API_CALL_STATUSES.SUCCESS,
            })
          },
          error: () => {
            this.setState({
              userStatus: API_CALL_STATUSES.ERROR,
            })
          },
        })
      },
      error: () => {
        this.setState({
          userStatus: API_CALL_STATUSES.ERROR,
        })
      },
    })
  }

  render() {
    const { ioConsole } = window
    const { title } = this.props
    const { timer, userData, userStatus } = this.state
    return (
      <Layout>
        {userStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        {(userStatus === API_CALL_STATUSES.SUCCESS ||
          userStatus === API_CALL_STATUSES.ERROR) && (
          <ClientOnly>
            <div id="containerOuter">
              <div id="container" style={{ width: "350px" }}>
                <p className="subText">
                  {userStatus === API_CALL_STATUSES.SUCCESS && (
                    <span>
                      You are logged in as{" "}
                      {userData.firstName + " " + userData.lastName}(
                      {userData.email})
                    </span>
                  )}
                  {userStatus === API_CALL_STATUSES.ERROR && (
                    <span>You are logged in as another user.</span>
                  )}
                  <br />
                  <Link to={`/logout/${getRedirectPathFromUrl()}`}>Logout</Link>
                  <br />
                  <RedirectTimer
                    initTime={timer}
                    redirectUrl={ioConsole}
                    redirectText="rapyuta.io"
                  />
                </p>
              </div>
            </div>
          </ClientOnly>
        )}
      </Layout>
    )
  }
}

export default TokenExist
